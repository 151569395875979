import React from "react";

import { Link } from "gatsby";

import { Edge } from "@/types";

import * as styles from "./Feed.module.scss";

type Props = {
  edges: Array<Edge>;
};

const Feed: React.FC<Props> = ({ edges }: Props) => (
  <div className={styles.feed}>
    {/*
    <div className={styles.startupsGroup}>
      <a target="_blank" href="https://wizlogo.com/">
        <div className={styles.startups}> 
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M14 28C6.268 28 0 21.732 0 14C0 6.268 6.268 0 14 0C21.732 0 28 6.268 28 14C28 21.732 21.732 28 14 28ZM12.7296 15.0768L14.3792 18.8856C14.8912 20.0672 16.5336 20.192 17.2224 19.1016L22.2496 11.1448C22.3614 10.9676 22.4368 10.77 22.4716 10.5634C22.5064 10.3568 22.4997 10.1454 22.4521 9.94135C22.4045 9.73734 22.3168 9.54482 22.1941 9.37499C22.0715 9.20515 21.9163 9.06137 21.7376 8.952C21.3758 8.72836 20.9406 8.65602 20.526 8.75063C20.1113 8.84524 19.7506 9.09918 19.5216 9.4576L16.1248 14.8336L14.3208 10.6688C13.7808 9.424 12.0152 9.3728 11.4032 10.584L9.5504 14.2488L8.096 12.3304C7.8377 11.9922 7.45667 11.7691 7.03527 11.7096C6.61387 11.65 6.18595 11.7586 5.844 12.012C5.67524 12.1361 5.53285 12.2926 5.42512 12.4722C5.31738 12.6519 5.24645 12.8512 5.21645 13.0585C5.18645 13.2658 5.19798 13.4771 5.25036 13.6799C5.30275 13.8827 5.39495 14.0731 5.5216 14.24L8.5376 18.2192C9.256 19.1656 10.7296 19.0344 11.264 17.976L12.7296 15.0768Z" fill="var(--color-typographic-base-font)"/>
          </svg>
          <div className={styles.startupInfo}>
            <p>Wizlogo</p>
            <p>Create, Manage & Grow Your Brand</p>
          </div>
        </div>
      </a>
      <a target="_blank" href="https://okinos.co">
        <div className={styles.startups}> 
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M24.8894 5.09042L22.9095 3.11052L14 12.0201V15.9799L22.9095 24.8894L24.8894 22.9095L17.3799 15.4H28V12.6H17.3799L24.8894 5.09042Z" fill="var(--color-typographic-base-font)"/>
          <path d="M9.13283 20.847C8.3677 20.3022 7.6978 19.6323 7.15294 18.8671L3.11055 22.9095L5.09045 24.8894L9.13283 20.847Z" fill="var(--color-typographic-base-font)"/>
          <path d="M5.71614 15.4C5.63976 14.9447 5.6 14.477 5.6 14C5.6 13.523 5.63976 13.0553 5.71614 12.6H0V15.4H5.71614Z" fill="var(--color-typographic-base-font)"/>
          <path d="M7.15296 9.13285L3.11055 5.09043L5.09045 3.11054L9.13286 7.15295C8.36773 7.69781 7.69782 8.36772 7.15296 9.13285Z" fill="var(--color-typographic-base-font)"/>
          <path d="M12.6 0H15.4V28H12.6V0Z" fill="var(--color-typographic-base-font)"/>
          </svg>
          <div className={styles.startupInfo}>
            <p>Okinos</p>
            <p>Custom Software Development</p>
          </div>
        </div>
      </a>
    </div>
    */}
    {edges.map((edge) => (
      <div className={styles.item} key={edge.node.fields.slug}>
        <div className={styles.meta}>
          <time
            className={styles.time}
            dateTime={new Date(edge.node.frontmatter.date).toLocaleDateString(
              "en-US",
              { year: "numeric", month: "long", day: "numeric" },
            )}
          >
            {new Date(edge.node.frontmatter.date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric" ,
            })}
          </time>
          <span className={styles.divider} />
          {/*
          <span className={styles.category}>
            <Link to={edge.node.fields.categorySlug} className={styles.link}>
              {edge.node.frontmatter.category}
            </Link>
          </span>
          */}
        </div>
        <h2 className={styles.title}>
          <Link
            className={styles.link}
            to={edge.node.frontmatter?.slug || edge.node.fields.slug}
          >
            {edge.node.frontmatter.title}
          </Link>
        </h2>
        <p className={styles.description}>
          {edge.node.frontmatter.description}
        </p>
        <Link
          className={styles.more}
          to={edge.node.frontmatter?.slug || edge.node.fields.slug}
        >
          Read more
        </Link>
        <hr></hr>
      </div>
    ))}
  </div>
);

export default Feed;
